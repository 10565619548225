import useSegment from 'Hooks/useSegment/useSegment';
import { useSegmentCommonProps } from '../useSegmentCommonProps/useSegmentCommonProps';
import { useSegmentProperties } from '../useSegmentProperties/useSegmentProperties';

interface UseRenderEventsReturn {
  triggerInitRenderingProcess: (params: {
    occurence_id: string;
    page_name: string;
    is_core_event: boolean;
  }) => void;
  triggerInteractionRenderedProcess: (params: {
    occurence_id: string;
    page_name: string;
    is_core_event: boolean;
  }) => void;
}

export default function useRenderEvents(): UseRenderEventsReturn {
  const { initRenderingProcess, interactionRenderedRenderingProcess } = useSegment();
  const {
    screen_name,
    country,
    user_type,
    vendor_id,
    vendor_name,
    report_subtype,
    report_type,
    vendor_country,
  } = useSegmentCommonProps();

  const {
    app_display_name,
    app_id,
    origin_workspace_label,
    origin_workspace_tracking_id,
    page_url,
    user_affiliation,
    user_country,
    user_id,
    user_email,
    vendor_display_name,
    vendor_service_model,
    vendor_tier_name,
    page_label,
    origin_theme_label,
    origin_theme_tracking_id,
    origin_report_label,
    origin_report_tracking_id,
  } = useSegmentProperties();

  const triggerInitRenderingProcess = ({
    occurence_id,
    page_name,
    is_core_event,
  }: {
    occurence_id: string;
    page_name: string;
    is_core_event: boolean;
  }) => {
    initRenderingProcess({
      country,
      report_subtype,
      report_type,
      screen_name,
      user_type,
      vendor_id,
      vendor_name,
      app_display_name,
      app_id,
      is_core_event,
      page_label,
      page_name,
      page_url,
      user_email,
      user_id,
      user_affiliation,
      user_country,
      vendor_display_name,
      vendor_service_model,
      vendor_tier_name,
      origin_workspace_label,
      origin_workspace_tracking_id,
      origin_theme_label,
      origin_theme_tracking_id,
      origin_report_label,
      origin_report_tracking_id,
      occurence_id,
    });
  };

  const triggerInteractionRenderedProcess = ({
    occurence_id,
    page_name,
    is_core_event,
  }: {
    occurence_id: string;
    page_name: string;
    is_core_event: boolean;
  }) => {
    interactionRenderedRenderingProcess({
      app_display_name,
      app_id,
      page_url,
      is_core_event,
      page_label,
      page_name,
      user_country,
      occurence_id,
      user_email,
      user_id,
      user_affiliation,
      vendor_country,
      vendor_display_name,
      vendor_id,
      vendor_service_model,
      origin_report_label,
      origin_report_tracking_id,
      origin_theme_label,
      origin_theme_tracking_id,
      origin_workspace_label,
      origin_workspace_tracking_id,
      vendor_tier_name,
    });
  };

  return {
    triggerInitRenderingProcess,
    triggerInteractionRenderedProcess,
  };
}

import Api from 'Api/Api';
import APIEndpoint from 'Api/APIEndpoint';
import getAPIBaseURL from 'Api/getAPIBaseURL';
import { EmbedReportResponse } from 'Domain/NewEmbedReport';

export type getEmbedReportProps = {
  lang: string;
  workspaceId: string;
  reportId?: string;
  permissionId?: string;
  vendorId?: string;
  country: string;
  powerBiWorkspaceId?: string;
  powerBiReportId?: string;
};

export const getEmbedReport = async ({
  lang,
  workspaceId,
  reportId,
  permissionId,
  vendorId,
  country,
  powerBiWorkspaceId,
  powerBiReportId,
}: getEmbedReportProps): Promise<EmbedReportResponse> => {
  const baseUrl = getAPIBaseURL(APIEndpoint.GET_EMBED_REPORT, country);

  const headers: Record<string, string> = {};
  if (country) {
    headers.country = country;
  }

  const embedReport = 'embed-report';
  const embedWorkspace = `${embedReport}/highlight-section`;

  const requestUrl = powerBiWorkspaceId ? embedWorkspace : embedReport;

  const response = await Api.get({
    url: `config/${requestUrl}`,
    baseURL: baseUrl,
    params: {
      lang,
      workspaceId,
      reportId,
      permissionId: permissionId || undefined,
      vendorId: vendorId || undefined,
      powerBiWorkspaceId,
      powerBiReportId,
    },
    headers,
  });

  return response.data;
};

import MessageMap from 'I18n/i18n';

const id: MessageMap = {
  test: {
    translate: 'Translate',
    missing: 'Only Indonesian option available',
  },

  SELECTED_LANGUAGE: 'id-ID',

  // NEED COPYDECK
  MAIN_PAGE: {
    TITLE: 'Analitik',
    BTGUIDE_SECTION: {
      TITLE: 'Panduan transformasi bisnis',
      DESCRIPTION: 'Panduan langkah demi langkah untuk memaksimalkan nilai bersama BEES.',
      LINK: 'Buka Panduan transformasi bisnis',
    },
    REPORT_SECTION: {
      TITLE: 'Laporan',
      SUBTITLE:
        'Jelajahi laporan terperinci Anda. Anda dapat menandai favorit Anda dengan bintang untuk berada di daftar teratas.',
      BUTTON: 'Tampilkan semua laporan',
    },
    WORKSPACE_SECTION: {
      TITLE: 'Tampilan tersedia',
      DESCRIPTION: 'Akses berbagai kumpulan data yang tersedia untuk Anda.',
      BUTTON: 'Tampilkan semua tampilan',
    },
    EMPTY_STATE: {
      TITLE: 'Oops',
      DESCRIPTION: 'Apparently there is no workspace created or available for you.',
    },
    BIG_NUMBERS_SECTION: {
      TITLE: 'Sorotan bulan ini',
      SUBTITLE: 'Selayang Pandang KPI Pelanggan BEES.',
    },
    // NEED COPYDECK
    DATASET_SECTION: {
      TITLE: 'Datasets',
      SUBTITLE: 'Download the data associated with each thematic report.',
    },
  },

  // NEED COPYDECK
  CARD_SECTION: {
    EMPTY_MESSAGE: 'Oops, sorry! No results found.',
  },

  WORKSPACES_PAGE: {
    // NEED COPY DECK
    TITLE: 'Ruang kerja',
    SEARCHFIELD: {
      PLACEHOLDER: 'Cari berdasarkan jenis pengguna',
    },
  },

  HEADER: {
    ANALYTICS_BUTTON: 'Analytics admin',
  },

  WORKSPACE_SELECTED_CARD: {
    CHANGE: 'Mengubah',
    NO_SEGMENT: 'No segment selected',
    VIEW: 'Tampilan saat ini:',
  },

  CHANGE_WORKSPACE_MODAL: {
    TITLE: 'Ubah tampilan',
    DESCRIPTION: 'Pilih opsi untuk mengubah tampilan.',
    WORKSPACE: {
      LABEL: 'Jenis pengguna',
      PLACEHOLDER: 'Pilih',
    },
    SEGMENT: {
      LABEL: 'Tampilan',
      PLACEHOLDER: 'Pilih',
    },
    COUNTRY: {
      LABEL: 'Negara',
      PLACEHOLDER: 'Pilih',
    },
    SERVICE_MODEL: {
      LABEL: 'Model bisnis',
      PLACEHOLDER: 'Pilih',
    },
    VENDOR: {
      LABEL: 'Perusahaan',
      PLACEHOLDER: 'Pilih',
      LOADING: 'Loading...',
    },
    CONFIRM_BUTTON: 'Mengubah',
  },

  WORKSPACE_CARD: {
    SINGULAR_TEXT: 'tampilan',
    PLURAL_TEXT: 'tampilan',
  },

  BREADCRUMBS: {
    REPORTS: 'MEMULAI',
  },

  REPORTS_LIST_PAGE: {
    TITLE: 'Laporan',
    SEARCH: {
      PLACEHOLDER: 'Search by theme or report name',
    },
    NOT_FOUND_STATE: {
      TITLE: 'No results found',
      TEXT: 'Check your spelling or try using different search terms.',
    },
    TOAST: {
      FIRST_PART: 'Sekarang Anda berada dalam',
      LAST_PART: 'tampilan',
    },
    REPORTS_QUANTITY: ' laporan',
  },

  REPORTS_PAGE: {
    REPORTS_PAGE_TITLE: 'Data dan analitik',
    REPORTS_PAGE_VENDOR: 'Vendor',
    CONTENT_REPORTS_TAB_VIEW:
      'Measure your performance across all delivered orders within the selected timeframe.',
  },

  GLOBAL_OVERVIEW_PAGE: {
    GLOBAL_OVERVIEW_PAGE_TITLE: 'Ikhtisar global',
  },

  CONTAINER_REPORTS: {
    CONTAINER_ERROR: 'Terjadi kesalahan',
    CONTAINER_ERROR_LABEL: 'Mohon maaf, kami mengalami masalah teknis. Cobalah lagi.',
  },

  SELECT_RANGE_DATE_CSV_MODAL: {
    TITLE: 'Download CSV file',
    DESCRIPTION: 'You are creating a report with information from',
    YEAR_LABEL: 'Year',
    MONTH_LABEL: 'Month',
    NO_INFO_AVAILABLE:
      "There's no information for the selected period. Please try a different date.",
    CANCEL: 'Cancel',
    DOWNLOAD: 'Download',
  },

  CSV_DOWNLOAD_SELECT: {
    PLACEHOLDER: 'Ekspor file',
  },

  HELP_CENTER_MODAL: {
    TITLE: 'Cara mengakses file CSV',
    DESCRIPTION:
      'Kurang familier dengan file CSV? Bacalah panduan langkah demi langkah untuk menggunakan file CSV.',
    LINK: 'Tampilkan panduan file CSV',
    PROCEED_BUTTON: 'Lanjut mengunduh',
  },

  GET_STARTED: {
    LOADING: 'Memuat',
    TITLE: 'Memulai',
    PARAGRAPH: 'Pilih vendor untuk melihat data dan wawasan mereka.',
    NEXT: 'Berikutnya',
  },

  SELECTED_VENDOR: {
    CHANGE: 'Ubah',
    TITLE: 'You are viewing',
  },

  SELECT_TOOLTIP: {
    OPEN: 'Open',
    CLEAR: 'Clear',
  },

  MONTH: {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December',
  },

  COMMON: {
    CANCEL: 'Batalkan',
    CONFIRM: 'Confirm',
  },
  LOADING: {
    TITLE: 'Memuat',
    PARAGRAPH: 'Kami sedang menyiapkan halaman untuk Anda.',
  },
  SELECT_VENDOR: {
    TITLE: 'Pilih vendor',
  },
  UNAUTHORIZED: {
    ERROR_TITLE: 'Hmm… Akses ditolak',
    ERROR_DESCRIPTION: 'Sepertinya Anda tidak memiliki izin untuk mengakses halaman ini',
  },
  EXPORT_FILE: {
    SOMETHING_WRONG: 'Something went wrong. Please try again.',
    PREPARING_DOWNLOAD: 'Mempersiapkan file CSV untuk diunduh.',
    SUCCESS_DOWNLOAD: 'Unduhan berhasil diselesaikan',
  },

  CONTACT_US: {
    TOAST_SUCCESS: 'Request successfully sent! Our team will contact you shortly.',
    TOAST_ERROR: 'Something went wrong. Please try again.',
  },
  COUNTRIES: {
    AR: 'Argentina',
    BO: 'Bolivia',
    BR: 'Brasil',
    CL: 'Chili',
    CO: 'Kolumbia',
    DO: 'Republik Dominika',
    EC: 'Ekuador',
    ID: 'Indonesia',
    MX: 'Meksiko',
    PA: 'Panama',
    PE: 'Peru',
    PH: 'Filipina',
    PY: 'Paraguay',
    ZA: 'Afrika Selatan',
    CH: 'Switzerland',
    BE: 'Belgium',
    IC: 'Canary Island',
    ES: 'Spain',
    DE: 'Germany',
    GB: 'United Kingdom',
    NL: 'Netherlands',
    GLOBAL_OVERVIEW: 'Ikhtisar global',
  },
  COUNTRY_SELECT: {
    PLACEHOLDER: 'Pilih negara',
  },
  SERVICE_MODELS: {
    '1P': '1P',
    '3P-3L-SS': '3P Pro',
    '3P-3L-SF': 'DTaaS',
    '3P': 'DTaaS',
  },
  SERVICE_MODEL_SELECT: {
    PLACEHOLDER: 'Pilih model bisnis',
  },
  VENDOR_SELECT: {
    PLACEHOLDER: 'Pilih vendor',
    LOADING: 'Memuat...',
  },

  EMBED_REPORT: {
    LOADING_EMBED_REPORT: {
      TITLE: 'Mengambil data',
      BODY: 'Langkah 1 dari 3',
    },

    LOADING_POWERBI: {
      TITLE: 'Memuat laporan',
      BODY: 'Langkah 2 dari 3',
    },

    LOADING_DATA: {
      TITLE: 'Hampir selesai',
      BODY: 'Langkah 3 dari 3',
    },
  },

  SIDEBAR: {
    GET_START: 'Get started',
    WORKSPACES: 'Workspaces',
    ANALYTICS_ADMIN: 'Analytics Admin',
    MAIN_PAGE: 'Main page',
  },
  BIG_NUMBERS: {
    BUTTON: 'Buka laporan terperinci',
  },

  SWITCH_LOADING: {
    TITLE: 'Mengalihkan tampilan',
    ENTERING: 'Anda memasukkan',
    USER: 'user: ',
    PARAGRAPH: 'Tunggu hingga tampilan dimuat.',
  },

  BEES_ASSISTANT: {
    DESCRIPTION: 'Perlu bantuan untuk BEES Analytics? Kami hadir untuk membantu Anda.',
  },

  DATASET_TABLE: {
    SEARCH: 'Search',
    EMPTY_MESSAGE: 'No results found',
  },
};

export default id;
